<template>
  <div :class="`m-3 p-2 wrapper  wrapper--default`">
    <div class="text-center p-2">
      <h2>Afwezigheid</h2>
      <span @click="previousDay"><i class="fas fa-arrow-left"></i></span>
      <span class="text-center m-2">{{ day }}-{{ month }}-{{ year }}</span>
      <span @click="nextDay"><i class="fas fa-arrow-right"></i></span>
    </div>
    <hr>
    <Loading v-if="loading" />
    <div v-else class="flexbox spaceevenly">
      <table
        :class="`w-100 table--perweek table__border--default`"
      >
        <thead :class="`table__head--default`">
          <tr>
            <th class="table__head--text">Naam</th>
            <th class="table__head--text">Dag/Dagdeel</th>
            <th v-if="checkroles(['admin'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__head--text">Soort</th>
            <th class="table__head--text">Afdeling</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, key) in absence_registration"
            :key="key"
            :class="`table__row--color`"
          >
            <td class="table__cell--default">{{ item.Naam }}</td>
            <td class="table__cell--default">{{ item.Dagdeel }}</td>
            <td v-if="checkroles(['admin'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__cell--default">{{ item.SoortAanvraag }}</td>
            <td class="table__cell--default">{{ item.Afdeling }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import request from "@/functions/request.js";
import Loading from "@/components/Loading.vue";
import moment from "moment";
import { addTime, subtractTime } from "@/functions/date.js";

export default {
  components: { Loading },
  data: () => ({
    loading: null,
    full_date: null,
    absence_registration: null,
  }),
  created() {
    this.full_date = moment();
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;
      this.day = this.full_date.date();
      this.month = this.full_date.month() + 1;
      this.year = this.full_date.year();
      request(`absence-registration/${this.year}/${this.month}/${this.day}`, "GET").then(
        ({ absence_registration }) => {
          this.absence_registration = absence_registration;
          this.loading = false;
        }
      );
    },

    previousDay() {
      this.full_date = subtractTime(1, this.full_date, "days");
      this.getData();
    },

    nextDay() {
      this.full_date = addTime(1, this.full_date, "days");
      this.getData();
    },
  },
};
</script>
